import vowd from '../../assets/brands/logo-vowd.png'
import abdou from '../../assets/pictures/partner-abdou.png'
import nico from '../../assets/pictures/partner-nico.png'
import walid from '../../assets/pictures/partner-walid.jpg'


// styles
import './m-partners.css'
import './d-partners.css'

const partners = () => {

  const partnersArray = [
    {
      "partner": {
        "picture": `${vowd}`,
        "firstName": "Aaron",
        "lastName": "Divioka",
        "width": "960px",
        "height": "949px"
      },
      "statut": [
        "Directeur commercial"
      ],
      "Portfolio": [
        "https://www.linkedin.com/in/aaron-divioka-vowd-56258248/"
      ]
    },
    {
      "partner": {
        "picture": `${abdou}`,
        "firstName": "Abdouraouf",
        "lastName": "Youssouf",
        "width": "498px",
        "height": "501px"
      },
      "statut": [
        "Développeur React/NextJs & React-Native"
      ],
      "Portfolio": [
        "https://abdouraoufys.vercel.app/"
      ]
    },
    {
      "partner": {
        "picture": `${nico}`,
        "firstName": "Nicolas",
        "lastName": "Denys de Bonnaventure",
        "width": "636px",
        "height": "636px"
      },
      "statut": [
        "Infographiste & montage vidéo"
      ],
      "Portfolio": [
        "https://nicolasdenys69.wixsite.com/nicolas-denys"
      ]
    },
    {
      "partner": {
        "picture": `${walid}`,
        "firstName": "Walid",
        "lastName": "Benamor",
        "width": "500px",
        "height": "500px"
      },
      "statut": [
        "Développeur PHP & analyste fonctionnel"
      ],
      "Portfolio": [
        "https://devanpi.com/"
      ]
    }
  ]

  return (
    <section className='partners'>
        <h1 className='partners__title'>Staff</h1>
        {partnersArray.map((item, index) => (
          <ul key={index}>
            <li className='partners__card' onClick={() => window.location=`${item.Portfolio}`}>
              <div className='partners__card__wrapper'>
                <img className='partners__card__wrapper__pic' 
                    src={item.partner.picture} 
                    width={item.partner.width} 
                    height={item.partner.height} 
                    loading='lazy'
                    alt='pic' 
                />
              </div>
              <p className='partners__card__name'>
                {item.partner.firstName}
                <br/>
                {item.partner.lastName}
              </p>
              <p className='partners__card__statut'>{item.statut}</p>
              <p className='partners__card__portfolio'>{item.Portfolio}</p>
            </li>
          </ul>
        ))}
    </section>
  )
}

export default partners
