import * as Yup from 'yup'

const matchSociety = /^[a-zA-ZÀÁÂÆÇÈÉÊËÌÍÎÏÑÒÓÔÕÖÙÚÛÜÝàáâæçèéêëìíîïñòóôõöùúûüý -]+$/
const errorSociety = 'Le nom de la société ne correspond pas au format attendu'

const matchSiret = /^[0-9{14,14}]+$/
const errorSiret = 'Le numéro SIRET ne peut contenir que des chiffres. 14 chiffres sont requis'

const matchFirstName = /^[a-zA-ZÀÁÂÆÇÈÉÊËÌÍÎÏÑÒÓÔÕÖÙÚÛÜÝàáâæçèéêëìíîïñòóôõöùúûüý -]+$/
const errorFirstName = 'Le prénom ne correspond pas au format attendu'

const matchLastName = /^[a-zA-ZÀÁÂÆÇÈÉÊËÌÍÎÏÑÒÓÔÕÖÙÚÛÜÝàáâæçèéêëìíîïñòóôõöùúûüý -]+$/
const errorLastName = 'Le nom ne correspond pas au format attendu'

const matchEmail = /^\w+([\.-_]?\w+)*@\w+([\.-_]?\w+)*(\.\w{2,3})+$/
const errorEmail = "L'adresse électronique n'est pas valide"

const matchMessage = /^[a-zA-Zéèêîçàôïùû0-9]+(?:['\s\-?,:!%"@;’=°_()&$€.a-zA-Zéèêîçàôïûù0-9]+)*$/
const errorMessage = 'Le message doit contenir des caractères valides. Certains caractères spéciaux ne sont pas autorisés. 2 à 500 caractères sont requis.'
export const validationSchema = Yup.object().shape({
  society: Yup.string().min(3, 'Le nom de la société doit contenir au minimum 3 caractères').required('Le champ Société est obligatoire')
    .max(30, 'Le nom de la société ne peut pas dépasser 30 caractères')
    .matches(matchSociety, errorSociety),
  siret: Yup.string().min(14, 'Le numéro SIRET doit contenir 14 chiffres').required('Le champ SIRET est obligatoire')
    .max(14, 'Le numéro SIRET doit contenir 14 chiffres')
    .matches(matchSiret, errorSiret),
  firstName: Yup.string().min(2, 'Le Prénom doit contenir au minimum 2 caractères').required('Le champ Prénom est obligatoire')
    .max(30, 'Le prénom doit contenir 30 caractères maximum')
    .matches(matchFirstName, errorFirstName),
  lastName: Yup.string().min(2, 'Le nom doit contenir au minimum 2 caractères').required('Le champ Nom est obligatoire')
    .max(30, 'Le nom doit contenir 30 caractères maximum')
    .matches(matchLastName, errorLastName),
  email: Yup.string()
    .matches(matchEmail, errorEmail)
    .required('Le champ Email est obligatoire'),
  projet: Yup.string(),
  budjget: Yup.string(),
  delai: Yup.string(),
  message: Yup.string()
  .min(3, 'Le message doit contenir au minimum 3 caractères')
  .max(500, 'Le message ne doit pas dépasser 500 caractères')
  .matches(matchMessage, errorMessage)
});